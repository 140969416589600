.container {
  background-color: var(--gray);
  padding: 2rem 0 4rem;
}

.board {
  background-color: var(--white);
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.25);
  margin-top: 1rem;
}
.board > div {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.board img {
  max-width: 100%;
  max-height: 40px;
}
.board p {
  text-align: center;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 400;
  margin-top: 0.4rem;
}