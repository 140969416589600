* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

:root {
  --white:#fff;
  --gray: #F0F0F0;
  --dark: #37474F;
  --cta: #4DB6AC;
  --title: #455A64;
  --text: #78909C;

  --pink: #F06292;
  --green: #9CCC65;
  --blue: #64B5F6;
  --yellow: #FBB03B;
  --purple: #9575CD;
}

body {
  background: var(--white);
  color: var(--text);
  font: 300 1rem "Roboto", sans-serif;
}
button {
  cursor: pointer;
}
a {
  color: inherit;
  text-decoration: none;
}

.title {
  font: 700 2rem "Roboto Condensed", sans-serif !important;
  color: var(--title);
}


.text {
  font: 300 1.2rem "Roboto", sans-serif !important;
  color: var(--text);
}
