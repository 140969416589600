.container {
  padding: 1rem 0;
  background-color: var(--white);
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 999;
}

.container img {
  max-height: 2.5rem;
}

.menuButton {
  font: 0.875rem "Roboto Condensed", sans-serif !important;
  color: var(--text) !important;
  margin: 0 0.25rem !important;
}