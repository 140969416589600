.container {
  margin-top: 2rem;
  margin-left: 1rem;
  background-color: var(--title);
  border-radius: 1rem;
  padding: 1rem;
  position: relative;

  box-shadow: 0 0.5rem 2rem rgba(0, 0, 0, 0.25);
}

.container > span {
  color: var(--white);
  font-size: 1.5rem;
}

.ball {
  font-size: 1.6rem;
  font-weight: 900;
  color: var(--white);
  width: 40px;
  height: 40px;
  text-align: center;

  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;

  transform: translate( -50%, -50% );

  display: flex;
  justify-content: center;
  align-items: center;
}