.light {
  background-color: var(--white) !important;
}
.gray {
  background-color: var(--gray) !important;
}
.dark {
  background-color: var(--title) !important;
}
.dark h1, .dark p {
  color: var(--white) !important;
}

@media screen and (max-width: 600px) {
  .imgContainer {
    text-align: center !important;
  }
  .imgContainer img {
    max-width: 320px !important;
  }
}