.cta {
  background: var(--cta) !important;
  color: var(--white) !important;
  text-transform: uppercase;
  margin: 1rem 0 !important;
  font: 1rem "Roboto Condensed", sans-serif !important;

  transition: filter 0.4s !important;
}

.cta:hover {
  filter: brightness(0.9);
}

@media screen and (max-width: 600px) {
  .cta {
    width: 100%;
    padding: 1rem 0 !important;
  }
}